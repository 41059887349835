.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 24rem;
    width: 24rem;
    margin: 3rem;
    padding: 1rem;
    transition: 0.6s;
    cursor: pointer;
    -moz-perspective: 100rem;
    perspective: 100rem;
}

.card-side {
    transition: all 1s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 0.8rem;
    background-color: var(--secondary-color);
    overflow: hidden;
}

.card-side-back {
    transform: rotateY(180deg);
}

.card:hover .card-side-back {
    transform: rotateY(0);
}

.card:hover .card-side-front {
    transform: rotateY(-180deg);
}

.card-theme {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%, -50%);
}

.card-subject {
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 3rem;
}

.service-card-image {
    height: 15rem;
    width: 25rem;
}

.card-details {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-details ul {
    margin: 3rem auto;
    padding: auto;
}

.card-details ul li {
    font-size: 1.1rem;
    text-align: left;
    padding: 0.7rem;
}

@media screen and (max-width: 768px) {
    .card {
        height: 24rem;
        width: 20rem;
        margin: 1.5rem;
    }

    .service-card-image {
        width: 20rem;
    }
}