.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.contact-title {
    font-size: 2.8rem;
    font-weight: 500;
    color: #803813;
    margin-bottom: 3rem;
}

.contact-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 2rem;
    color: #000;
    border-radius: 8px;
    border: 1px solid var(--secondary-color);
}

.contact-number, .contact-email {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
}

.contact-number > svg, .contact-email > svg {
    position: relative;
    top: 0.1rem;
    margin-right: 0.5rem;
}

@media screen and (max-width: 912px) {
    .contact-container {
        padding-top: 2rem;
    }
    .contact-title {
        font-size: 2.4rem;
    }
    .contact-card {
        width: 90%;
        padding: 1rem;
    }
}