.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    padding-top: 5rem;
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.not-found-text {
    font-size: 2.8rem;
    font-weight: 500;
    color: var(--secondary-color);
    margin-bottom: 3rem;
}

@media screen and (max-width: 912px) {
    .not-found-container {
        padding-top: 5rem;
    }
    .not-found-text {
        font-size: 2.4rem;
    }
}