.footer-container {
  display: flex;
  flex-direction: column;
}

.row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: white;
  padding: 1rem;
  padding-bottom: 0;
  background-color: var(--secondary-color);
}

.row2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0rem;
  background-color: var(--secondary-color);
  /* background-color: #3D2B1F; */
}

.footer-about {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 1rem;
  text-align: left;
}

.footer-about-title {
  font-size: 2rem;
  font-weight: 200;
}

.footer-about-text {
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 0;
  line-height: 1.5;
  text-align: justify;
}

.footer-quick-links {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
}

.footer-quick-links-title {
  font-size: 2rem;
  font-weight: 200;  
}

.footer-quick-links-list {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding-left: 0rem;
  color: var(--footer-color);
  list-style: none;
  text-align: center;
}

.footer-quick-links-list-item {
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
}

.footer-quick-links-list-item-link {
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--footer-color);
  text-decoration: none;
}

.footer-quick-links-list-item-link:hover {
  color: #B6B6B4;
}

.footer-copyright-p {
  font-size: 1.1rem;
  color: var(--footer-color);
  text-align: center;
}

.footer-socials {
  margin: auto 0;
  padding: 0;
}

.footer-socials-link {
  margin: 0.2rem;
  padding: 0;
  color: var(--footer-color);
  text-decoration: none;
}

.footer-socials-link-icon {
  font-size: 2rem;
  color: var(--footer-color);
}

.footer-socials-link-icon:hover {
  color: #B6B6B4;
}

@media screen and (max-width: 912px) {
  .footer-container {
    flex-direction: column;
  }

  .row1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .row2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-about {
    width: 100%;
    text-align: center;
  }

  .footer-quick-links {
    width: 100%;
    text-align: center;
  }

  .footer-quick-links-list {
    padding-left: 0;
  }
}