.events-section {
    overflow: hidden;
    height: 75vh;
}

.slide-control {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 35%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.slide-control>button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: #000;
    padding: 0 1rem;
}

.slide-control>button>div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: scale(1.5);
}

.all-events {
    margin-top: 5rem;
}

.event-heading {
    margin-bottom: 1rem;
}

.event-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 2rem 3rem;
}

@media screen and (max-width: 912px) {
    .events-section {
        padding-top: 4rem;
        height: 75vh;
    }
    .slide-control {
        display: none;
    }

    .all-events {
        margin-top: 0;
    }

    .event-heading {
        margin-top: 0.5rem;
        padding: 0 0.5rem;
        font-size: 2.3rem !important;
    }

    .event-cards-container {
        padding: 1rem;
    }
}