.home-container {
  /* position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; */
  background-color: #EDCDBB;
}

.home-landing-section {
  height: 100vh;
  margin: 0;
  background-color: #FFEDDB;
}

.landing-background-img-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.landing-background-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.8);
}

.landing-background-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(255, 237, 219, 0.85);
  opacity: 0.8;
}

.landing-content {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  z-index: 100 !important;
}

.landing-text-container {
  text-align: left;
  padding: 0.5rem;
  margin-left: 6rem;
  font-family: 'Caudex', 'Cardo';
}

.landing-header {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: 'Reborn', 'Cardo';
  font-size: 4.6rem;
  font-weight: 500;
  color: var(--secondary-color);
}

.a-style {
  font-family: 'Megrim', 'Cardo';
  font-size: 6rem;
}

.q-style {
  font-family: 'Recoleta', 'Cardo';
  font-size: 5.2rem;
}

.landing-text {
  width: 36rem;
  color: var(--secondary-color);
  font-size: 2.2rem;
  font-weight: 500;
  margin-top: 1.8rem;
  margin-bottom: 3rem;
}

.tag-word-rotation {
  position: absolute;
  display: inline-block;
  font-weight: 600;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
}

.tag-word-rotation.show {
  transform: scale(1.05);
  opacity: 1;
  animation: rotateTagWords 12s linear infinite;
}

@keyframes rotateTagWords {

  0%,
  100% {
    opacity: 0;
    transform: scale(1);
  }

  10%,
  20% {
    opacity: 1;
    transform: scale(1.05);
  }

  60%,
  90% {
    opacity: 0;
  }
}

.landing-page-btn,
.about-btn {
  background-color: transparent;
  color: #000;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0.6rem 1rem;
  border: 2px solid black;
  border-radius: 0.4rem;
  transition: 0.6s;
  z-index: 100;
  cursor: pointer;
}

.landing-img-container {
  padding: 0.5rem;
  margin-right: 4rem;
}

.landing-img-overlay {
  background-color: rgba(255, 237, 219, 0.85);
}

.landing-img {
  max-width: 45vw;
  margin-top: 5rem;
  filter: brightness(1);
  border-radius: 0.4rem;
  box-shadow: 0.6rem 0.6rem rgba(0, 0, 0, 0.3);
}


.home-about-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #fff;
}

.home-about-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60vw;
  z-index: 100;
}

.about-header {
  font-size: 3.2rem;
  font-weight: 200;
  margin: 1rem 0 4rem 0;
  color: #000;
  text-align: center;
}

.about-text {
  font-size: 1.6rem;
  font-weight: 300;
  margin-top: 3rem;
  line-height: 1.5;
  color: #2F231A;
  text-align: justify;
}

.home-services-section {
  /* height: 100vh; */
  background-color: var(--primary-color);
  padding: 2rem 0;
  padding-bottom: 4rem;
}

.service-title {
  margin-top: 4rem;
  font-size: 3.3rem;
  font-weight: 200;
  color: #000;
}

.service-text {
  font-size: 1.6rem;
  font-weight: 300;
  margin-top: 3rem;
  line-height: 1.5;
  color: #2F231A;
}

.service-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin-top: 3rem;
}

@media screen and (max-width: 768px) {
  .home-landing-section {
    max-width: 100vw;
  }

  .landing-background-img-overlay {
    height: 100vh;
  }

  .landing-background-img {
    height: 100vh;
  }

  .landing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-width: 100vw;
    margin: 0;
    padding: 0;
  }

  .landing-img-container {
    all: unset;
    display: none;
    padding: 0.5rem;
  }

  .landing-img {
    max-width: 95vw;
    margin-top: 5rem;
  }

  .landing-text-container {
    all: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    color: #803813;
  }

  .landing-header {
    all: unset;
    font-family: 'Reborn', serif;
    font-weight: 500;
    font-size: 2.7rem;
    margin-bottom: 2rem;
    padding: 0 0.5rem;
  }

  .a-style {
    font-size: 3.6rem;
  }

  .landing-text {
    all: unset;
    width: 80%;
    max-width: 100vw;
    font-size: 1.5rem;
    margin: auto;
    margin-bottom: 2rem;
    text-align: justify;
  }

  .landing-text > span {
    margin-left: 0;
  }

  .landing-page-btn {
    margin-top: 1rem;
    font-size: 1.1rem;
  }

  .home-about-section {
    height: auto;
    padding-top: 0;
  }

  .home-about-container {
    height: auto;
    width: 85vw;
  }

  .about-header {
    font-size: 2rem;
    margin-top: 5rem;
    text-align: justify;
  }

  .about-text {
    font-size: 1.4rem;
    margin: 3rem auto 1rem auto;
    text-align: justify;
  }

  .about-btn {
    margin-bottom: 2rem;
  }

  .about-link {
    margin: 0rem auto 2rem auto;
  }

  .home-services-section {
    height: auto;
    padding-top: 1rem;
  }

  .service-title {
    font-size: 2.4rem;
  }

  .service-text {
    max-width: 95vw;
    font-size: 1.6rem;
    margin-top: 1rem;
  }

  .service-card-container {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .service-card {
    width: 70vw;
  }
}