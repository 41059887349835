.event-card {
    width: auto;
    max-width: 40%;
    height: auto;
    margin: 1rem 0;
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid black;
    transition: transform 0.3s ease;
}

.event-card-image-container {
    overflow: hidden;
    height: 20rem;
    border: 1px solid var(--secondary-color);
    border-radius: 8px;
}

.event-card-image {
    height: 20rem;
    width: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
    
}

.event-card-image:hover {
    transform: scale(1.05);
}

.event-card-info {
    padding: 20px;
}

.event-card-name {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.event-card-date {
    font-size: 18px;
    margin-bottom: 10px;
    color: #666;
}

.event-card-text {
    font-size: 18px;
    color: #666;
}

@media screen and (max-width: 912px) {
    .event-card {
        width: 95%;
        max-width: 95%;
    }
    .event-card-image-container {
        height: 15rem;
    }
    .event-card-image {
        height: 15rem;
    }

    .event-card-text {
        text-align: left;
    }
}