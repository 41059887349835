@import url('https://fonts.googleapis.com/css2?family=Cardo&family=Caudex:ital@0;1&display=swap');

@font-face {
  font-family: 'Reborn';
  src: url('../public/fonts/Reborn/Reborn.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: url('../public/fonts/recoleta/Recoleta-Light.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Megrim';
  src: url('../public/fonts/Megrim/Megrim.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

:root {
  --primary-color: #FFF1E6;
  --secondary-color: #8A3324;
}

body {
  margin: 0;
  font-family: 'Cardo', 'Caudex', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-color);
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  display: none;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(147, 58, 22);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}

a {
  text-decoration: none;
}