.slick-slide {
    position: relative;
    width: 100vw;
    background: #fff;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image-container {
    height: 70vh;
    width: 50%;
    position: relative;
}

.slider-img {
    max-height: 100%;
    z-index: 0;
    filter: brightness(95%);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.event-info {
    position: absolute;
    top: 0;
    right: 0;
    left: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 0 2rem;
    color: #213555;
}

.event-name {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.event-description {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;
}

.event-heading {
    color: var(--secondary-color);
    font-size: 2.6rem;
    font-weight: 500;
}

@media screen and (max-width: 912px) {
    .slick-slide {
        display: flex;
        flex-direction: column;
        /* max-height: 70vh; */
    }

    .overlay {
        all: unset;
        position: relative;
        top: 0;
        width: 100vw;
        height: 18rem;
        background: rgba(138, 51, 36, 0.1);
    }

    .event-info {
        all: unset;
        position: relative;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 0.5rem;
    }

    .event-name {
        font-size: 2rem;
        font-weight: 500;
    }

    .event-description {
        text-align: justify;
        width: 90%;
    }

    .image-container {
        all: unset;
        position: relative;
        width: 100%;
    }

    .slider-img {
        width: 100%;
        height: 18rem;
    }
}