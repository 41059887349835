.navbar {
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  z-index: 1000;
}

.navbar-logo {
  margin: auto 0 auto 2rem;
  padding: 0;
}

.logo-link-img {
  height: 3.6rem;
  margin: 0;
  padding: 0;
  border-radius: 50%;
}

.navbar-links {
  margin: auto 0;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

}

.navbar-link {
  margin-right: 2rem;
  color: #000;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 500;
  transition: 0.6s;
  z-index: 1000;
}

.navbar-link:last-child {
  margin-right: 4rem;
}

@media screen and (max-width: 768px) {
  .navbar {
    all: unset;
    position: fixed;
    top: 0;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    background-color: var(--primary-color);
    transition: background-color 0.3s ease-in-out;
    z-index: 1000;
    padding: 0.5rem;
  }

  .navbar-logo {
    margin: auto 0.5rem;
  }

  .logo-link-img {
    height: 2.9rem;
    width: 2.9rem;
  }

  .navbar-link {
    margin-right: 1rem;
    font-size: 1.2rem;
  }

  .navbar-link:last-child {
    margin-right: 2rem;
  }
}